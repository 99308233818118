import { DefaultProject, STAT_CONFIG_IS_OPEN_GTM, StatGetDeviceId, StatGetPackageId, StatGetPageId, StatGetUserId, StatInterface } from './config';
import { getDeviceHeight, getDeviceWidth } from './utils/getDeviceWidth';
import { getPageSource } from './utils/getPageSource';
import { getLocalObj } from './utils/locales';

//主变量集合
const __variablesMapA: any = {
    page_id: '', //页面ID,刷新页面时会更新
    user_id: '', //用户ID
    device_id: '', //设备ID
    package_id: '', //访问ID
};
//临时变量集合
const __variablesMapB: any = {
    __timer_page_leave: null, //定时器ID
};
// 是否已发送模块ID
const __hadSendMapA: any = {};

const BhStat = {
    locale: '',
    init: async ({ locale }: { locale: string }) => {
        if (STAT_CONFIG_IS_OPEN_GTM) {
            BhStat.locale = locale;
            __variablesMapA.device_id = await StatGetDeviceId();
            __variablesMapA.package_id = StatGetPackageId();
            __variablesMapA.page_id = StatGetPageId(__variablesMapA.package_id);
            __variablesMapA.user_id = StatGetUserId();

            BhStat.createVisitor();
            BhStat.enterPage();
            BhStat.leaveSetInterval();
        }
    },
    // 进入页面
    enterPage: async () => {
        const from_source = getPageSource() || DefaultProject;
        const from_page_id = null;
        const from_pathname = null;
        const sendData: any = {
            page_id: __variablesMapA.page_id,
            device_id: __variablesMapA.device_id,
            package_id: __variablesMapA.package_id,
            page_href: location.href,
            from_source: from_source,
            time_enter: Date.now(),
        };
        if (from_page_id) {
            sendData.from_page_id = from_page_id;
        }
        if (from_pathname) {
            sendData.from_pathname = from_pathname;
        }

        BhStat.send(sendData, StatInterface.createVisitTimeUrl);
    },
    // 离开页面|更新离开时间
    leavePage: async () => {
        BhStat.send(
            {
                page_id: __variablesMapA.page_id,
                package_id: __variablesMapA.package_id,
                time_leave: Date.now(),
            },
            StatInterface.updateVisitTimeUrl,
        );
    },
    // 设置定时器,每20秒发送一次
    leaveSetInterval: async () => {
        clearInterval(__variablesMapB.__timer_page_leave);
        __variablesMapB.__timer_page_leave = setInterval(() => {
            BhStat.leavePage();
        }, 20 * 1000);
    },
    push: async (params: any) => {
        const from_source = getPageSource() || DefaultProject;

        const data = {
            event: 'uaEvent',
            ...params,
            device_id: __variablesMapA.device_id,
            package_id: __variablesMapA.package_id,
            page_id: __variablesMapA.page_id,
            page_href: location.href,
            from_source: from_source,
        };
        if (data.page_href) {
            BhStat.send(data, StatInterface.baseUrlGa4);
        }

        //navigator.sendBeacon(BhStat.baseUrlGa4, JSON.stringify(data));
    },
    // 创建访问者
    createVisitor: async () => {
        const device_w = getDeviceWidth();
        const device_h = getDeviceHeight();
        const from_source = getPageSource() || DefaultProject;
        const user_id = __variablesMapA.user_id;
        const data: any = {
            device_id: __variablesMapA.device_id,
            package_id: __variablesMapA.package_id,

            device_w,
            device_h,

            page_href: location.href,
            from_source: from_source,
        };
        if (user_id) {
            data.user_id = user_id;
        }

        BhStat.send(data, StatInterface.createVisitorUrl);
        //navigator.sendBeacon(BhStat.baseUrlGa4, JSON.stringify(data));
    },
    // 页面模块--进入
    pageModuleCreate: async (params: any, options?: any) => {
        const { moduleId, title, enterCount, enterTime, leaveTime, duration } = params;
        const user_id = __variablesMapA.user_id;
        const page_id = __variablesMapA.page_id;
        const page_module_id = `${page_id}_${params.moduleId}_${params.enterCount}`; //记录的主键(页面ID,模块ID,当前模块进入次数)
        if (!enterTime || !leaveTime || !duration) {
            return;
        }
        // 该主键已发送过数据
        if (__hadSendMapA[page_module_id]) {
            return;
        }
        __hadSendMapA[page_module_id] = true;
        const data: any = {
            device_id: __variablesMapA.device_id,
            package_id: __variablesMapA.package_id,

            page_href: location.href,
            page_id,
            page_module_id,
            module_id: moduleId,
            module_title: title,
            enter_count: enterCount,
            time_enter: enterTime,
            time_leave: leaveTime,
            time_duration: duration, //停留时间
        };
        if (user_id) {
            data.user_id = user_id;
        }

        BhStat.send(data, StatInterface.baseUrlPageModuleCreate);
    },
    // 页面模块-离开
    pageModuleUpdate: async (params: any, options?: any) => {
        const { moduleId, duration, enterCount } = params;
        const page_id = __variablesMapA.page_id;
        const page_module_id = `${page_id}_${moduleId}_${enterCount}`; //记录的主键(页面ID,模块ID,当前模块进入次数)

        const data = {
            device_id: __variablesMapA.device_id,
            page_id,
            page_module_id,
            module_id: moduleId,
            time_duration: duration,
        };
        BhStat.send(data, StatInterface.baseUrlPageModuleUpdate);
    },
    send: async (data: any, sendUrl?: any) => {
        if (!BhStat.locale) {
            return;
        }
        const localeItem = getLocalObj(BhStat.locale);
        const app_id = localeItem.appId || 16;
        try {
            const formData = new URLSearchParams();
            for (const key in data) {
                formData.append(key, data[key]);
            }
            formData.append('app_id', `${app_id}`);
            formData.append('project', DefaultProject);
            navigator.sendBeacon(sendUrl || StatInterface.baseUrlGa4, formData); //formData);
        } catch (e) {}
    },
};

export default BhStat;
