'use client';

import getLocal from '@/app/utils/getLocal';
import { useGlobal } from '@/utils/useGlobal';
import { saveCookieForShopify } from '@elem/ui-common/utils/CookieForShopify';
import BhStat from 'lowcode/BhStat';

export default function NextLink({
    children,
    href,
    newWin,
    selfWin,
    ariaLabel = 'Ecoflow',
    as,
    dataLayer = null,
    className,
    stopPropagation = false,
    ...rest
}: any) {
    const { isMobile, useRouter, gtm, seoMap } = useGlobal();
    const { locale = 'us' as string } = getLocal();
    // const { locale } = useRouter();

    const hasLocale = new RegExp(`^/${locale}/`, 'i');
    let isUrl = false;
    if (typeof href === 'string') {
        isUrl = /^http/i.test(href?.trim());
    }
    let newHref = href;
    let newHrefExternal = href;

    // 判断是否在新窗口打开：a) http 开头的绝对地址；b) 有 newWin 属性的
    const isExternalUrl = (isUrl && href?.indexOf('www.ecoflow.com') === -1) || typeof newWin !== 'undefined';
    const aProps: any = {};

    const handleGTM = (params: any) => {
        if (Array.isArray(params)) {
            params.forEach((v) => gtm.push(v));
        } else if (params) {
            gtm.push(params);
            try {
                const eventObj = {
                    ...params,
                };
                BhStat.push(eventObj);
            } catch (error: any) {}
        }
    };

    // 如果 href 是对象
    if (typeof href === 'object') {
        if (href.hasOwnProperty('as')) {
            newHref.as = hasLocale.test(href.as) ? href.as : `/${locale}${href.as}`;
            newHrefExternal = newHref.as;
        } else {
            newHref.pathname = hasLocale.test(href.pathname) ? href.pathname : `/${locale}${href.pathname}`;
            if (!newHref.pathname?.includes('[')) {
                newHrefExternal = newHref.pathname;
            }
        }
    } else if (typeof href === 'string') {
        newHref = hasLocale.test(newHref) || isUrl ? newHref : `/${locale}${newHref}`;
        if (!newHref.includes('[')) {
            newHrefExternal = newHref;
        }
    }

    const props = {
        href: newHref,
        //locale: false,
        ...rest,
    };

    if (typeof as !== 'undefined') {
        props.as = hasLocale.test(as) ? as : `/${locale}${as}`;
        newHrefExternal = props.as;
    }

    const isSeoHref = seoMap?.some((item: any) => {
        return (href === `/${item.url}` && item.type === 'PRODUCT') || (href === `/collections/${item.url}` && item.type === 'PRODUCT_CATEGORY');
    });

    if (isExternalUrl) {
        if (typeof selfWin === 'undefined') {
            aProps.onClick = (e: any) => {
                handleGTM(dataLayer);
                if (isMobile) {
                    return;
                }
                e.preventDefault();
                e.stopPropagation();

                const winOpen: any = window.open('', '_blank');
                saveCookieForShopify(newHrefExternal, locale);
                winOpen.location = newHrefExternal;
                return false;
            };
        } else {
            aProps.target = '_self';
            aProps.onClick = (e: any) => {
                handleGTM(dataLayer);
            };
        }
    } else if (isExternalUrl || isSeoHref) {
        aProps.onClick = (e: any) => {
            handleGTM(dataLayer);
        };
    }
    if (!href) {
        return children;
    }

    return isSeoHref || isExternalUrl ? (
        <a className={className} href={newHrefExternal} aria-label={props.ariaLabel} {...aProps}>
            {children}
        </a>
    ) : (
        <a
            {...props}
            className={className}
            target={props.target || '_self'}
            aria-label={ariaLabel}
            onClick={(e) => {
                if (stopPropagation) {
                    e.stopPropagation();
                }
                saveCookieForShopify(href, locale);
                handleGTM(dataLayer);
            }}
        >
            {children}
        </a>
    );
}
