import { getCookieUser } from '../utils/cookieFn';
import { getBrowserIdFromCanvas } from '../utils/getBrowserIdFromCanvas';
import { getCookie } from '../utils/getCookie';
import { getStringRandom } from '../utils/getStringRandom';
import { getTraceId } from '../utils/getTraceid';

// 不可以直接改这里来调试
const STAT_CONFIG_BASE_URL = process.env.NEXT_PUBLIC_BH_HOST || 'https://api-burying-point.ecoflow.com';

export const STAT_CONFIG_IS_OPEN_GTM = true;
export const device_id_key = 'ef_device_id_01'; // 设备ID-key
export const package_id_key = 'ef_package_id_01'; // 访问ID-key
export const StatInterface = {
    createVisitorUrl: `${STAT_CONFIG_BASE_URL}/gtm1/visitor/web/create`, //创建用户
    updateVisitorUrl: `${STAT_CONFIG_BASE_URL}/gtm1/visitor/web/update`, //更新用户ID

    baseUrlGa4: `${STAT_CONFIG_BASE_URL}/gtm1/events-m2/web/create`, //主动型事件-GA4
    baseUrlPageModuleCreate: `${STAT_CONFIG_BASE_URL}/gtm1/events-m1/web/create`, //主动型事件-自有-创建
    baseUrlPageModuleUpdate: `${STAT_CONFIG_BASE_URL}/gtm1/events-m1/web/update`, //主动型事件-自有-更新
    createVisitTimeUrl: `${STAT_CONFIG_BASE_URL}/gtm1/pages/web/create`, //创建页面路径事件
    updateVisitTimeUrl: `${STAT_CONFIG_BASE_URL}/gtm1/pages/web/update`, //更新页面路径结束时间
};
// 获取设备ID
export const StatGetDeviceId = async () => {
    const device_id_cookie = getCookie(device_id_key); // cookie的 device_id,用于不同域名共用
    const device_id_storage = localStorage.getItem(device_id_key); //将cookie下的 device_id转到 storage
    let device_id = device_id_cookie || device_id_storage;
    // 2个id都不存在,生成一个新的id
    if (!device_id) {
        device_id = await getBrowserIdFromCanvas();
    }

    // 设置 cookie 的 device_id
    if (!device_id_cookie) {
        //document.cookie = `device_id_2=${device_id};`;
    }
    // 设置 localStorage 的 device_id
    if (!device_id_storage) {
        localStorage.setItem(device_id_key, device_id);
    }

    return device_id;
};

// 获取访问ID
export const StatGetPackageId = () => {
    let package_id = sessionStorage.getItem(package_id_key);
    if (!package_id) {
        package_id = getTraceId(32);
        sessionStorage.setItem(package_id_key, package_id);
    }

    return package_id;
};
// 获取用户ID
export const StatGetUserId = () => {
    const info = getCookieUser() || {};

    return info?.user?.userId || '';
};

// 获取页面ID
export const StatGetPageId = (package_id: string) => {
    if (!package_id) {
        return '';
    }
    const string1 = getStringRandom(15); //随机字符串
    const page_id_2 = `${package_id}_${string1}`;
    return page_id_2;
};

export const DefaultProject = 'ecoflow'; // ecoflow:品牌官网 shopify:shopify商城 homebattery:家储官网
