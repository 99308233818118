//import { getRealPathname } from "./getRealPathname";

export const getPageSource = (type = '') => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    if (type === 'ref') {
        return params.get('ref');
    }
    const source = params.get('utm_campaign');
    if (source) {
        return source;
    }

    return '';
};
